<script setup>
import { bannerTypes } from '@@/components/Common/Banner';
import { getLoginError } from '@@/utils/LoginUtils';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { parseOpenMountainApiError } from '@@/utils/CommonUtils';
import { useLoginView } from '@@/composables/useLoginView';
import { useUserStore } from '@@/stores/User';
import EnterEmailCard from '@@/components/Login/EnterEmailCard.vue';
import EnterPasswordCard from '@@/components/Login/EnterPasswordCard.vue';
import LinkSentCard from '@@/components/Login/LinkSentCard.vue';

definePageMeta({ layout: 'guest-or-apply-promo-code' });

const {
  isLoginEnterEmailView,
  isLoginEnterPasswordView,
  isLoginLinkSentView,
} = useLoginView();
const userStore = useUserStore();

const pageTitle = computed(() => isLoginLinkSentView.value ? 'Check Your Email' : 'Log In');

const view = computed(() => {
  if (isLoginEnterEmailView.value) {
    return EnterEmailCard;
  }

  if (isLoginEnterPasswordView.value) {
    return EnterPasswordCard;
  }

  if (isLoginLinkSentView.value) {
    return LinkSentCard;
  }

  return null;
});

const handleMounted = () => {
  if (loginError) {
    const banner = {
      is_dismissable: true,
      message: loginError,
      type: bannerTypes.error,
    };
    userStore.setBanner({ banner });
  }
  else {
    userStore.getBanner({ canGetUserBanner: false });
  }
};

const { query } = useRoute();
const loginStore = useLoginStore();

const { error } = await useAsyncData(async () => {
  await loginStore.getPromoCode(query.promo_code);
  loginStore.setCredentials({ password: null });
  loginStore.setLoginView(loginViews.loginEnterEmail);
  return true;
});

if (error.value) {
  throw createError(parseOpenMountainApiError(error.value));
}

const loginError = getLoginError(loginStore);

onMounted(handleMounted);
</script>

<template>
  <PageContent
    :can-fetch-banner="false"
    custom-container-class="container page-content"
  >
    <div class="login-container">
      <SettingsPageHeader>
        {{ pageTitle }}
      </SettingsPageHeader>
      <div>
        <transition
          enter-active-class="fade-enter-transition"
          enter-from-class="fade-out"
          leave-active-class="fade-leave-transition"
          leave-to-class="fade-out"
          mode="out-in"
        >
          <component
            :is="view"
            card-class-names="tw-flex tw-flex-col tw-justify-center"
            :is-full-height="true"
          />
        </transition>
      </div>
      <div class="tw-pt-4">
        <LoginActionsCard />
      </div>
    </div>
  </PageContent>
</template>
